export const validations: any = {
  isCheckEmail: (value: string) => {
    const result = /^([A-Za-z0-9_-]+\.)*[A-Za-z0-9_-]+@[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*\.[A-Za-z]{2,6}$/.test(
      value
    );

    return !result;
  },

  isCheckEmpty: (value: string) => {
    const result = value.length;

    return !result;
  },

  isCheckMinLength: (value: string, minLength: number) => {
    const result = value.length >= minLength;

    return !result;
  },

  isCheckMaxLength: (value: string, maxLength: number) => {
    const result = value.length <= maxLength;

    return !result;
  },

  isCheckRepeatPassword: (value: string, password: string) => {
    const result = value === password;

    return !result;
  },
};

import { createAction } from '@reduxjs/toolkit';

import * as api from 'api';
import * as type from 'store/types';

export const postDisconnectStripe = {
  post: function () {
    return (dispatch: any) => {
      dispatch(this.postPending());

      return api.stripeApi
        .postDisconnectStripe()
        .then((response: any) => {
          if (response.status === 200) {
            return response.json().then((json: any) => dispatch(this.postSuccess(json)));
          } else {
            return response.json().then((json: any) => {
              throw new Error(JSON.stringify(json));
            });
          }
        })
        .catch((error: any) => {
          dispatch(this.postFailed(JSON.parse(error.message)));
          throw new Error();
        });
    };
  },

  postSuccess: createAction<any>(type.request.stripeType.postDisconnectStripeSuccess),
  postFailed: createAction<any>(type.request.stripeType.postDisconnectStripeFailed),
  postPending: createAction(type.request.stripeType.postDisconnectStripePending),
};

export const customPayment = {
  postConnectCustomPaymentSuccess: 'request/postConnectCustomPaymentSuccess',
  postConnectCustomPaymentFailed: 'request/postConnectCustomPaymentFailed',
  postConnectCustomPaymentPending: 'request/postConnectCustomPaymentPending',

  postDisconnectCustomPaymentSuccess: 'request/postDisconnectCustomPaymentSuccess',
  postDisconnectCustomPaymentFailed: 'request/postDisconnectCustomPaymentFailed',
  postDisconnectCustomPaymentPending: 'request/postDisconnectCustomPaymentPending',

  postCreateOrderCustomPaymentSuccess: 'request/postCreateOrderCustomPaymentSuccess',
  postCreateOrderCustomPaymentFailed: 'request/postCreateOrderCustomPaymentFailed',
  postCreateOrderCustomPaymentPending: 'request/postCreateOrderCustomPaymentPending',

  postCreateOrderServiceCustomPaymentSuccess: 'request/postCreateOrderServiceCustomPaymentSuccess',
  postCreateOrderServiceCustomPaymentFailed: 'request/postCreateOrderServiceCustomPaymentFailed',
  postCreateOrderServiceCustomPaymentPending: 'request/postCreateOrderServiceCustomPaymentPending',
};

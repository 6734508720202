import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/googleCalendar';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.getGoogleCalendarsMe.getSuccess, ({ getGoogleCalendarsMe }, action) => {
      getGoogleCalendarsMe.status = 'success';
      getGoogleCalendarsMe.data = action.payload;
    })
    .addCase(action.getGoogleCalendarsMe.getFailed, ({ getGoogleCalendarsMe }, action) => {
      getGoogleCalendarsMe.status = 'failed';
      getGoogleCalendarsMe.error = action.payload;
    })
    .addCase(action.getGoogleCalendarsMe.getPending, ({ getGoogleCalendarsMe }, action) => {
      getGoogleCalendarsMe.status = 'pending';
      getGoogleCalendarsMe.error = null;
    });

  builder
    .addCase(action.putGoogleCalendars.putSuccess, ({ putGoogleCalendars }, action) => {
      putGoogleCalendars.status = 'success';
    })
    .addCase(action.putGoogleCalendars.putFailed, ({ putGoogleCalendars }, action) => {
      putGoogleCalendars.status = 'failed';
      putGoogleCalendars.error = action.payload;
    })
    .addCase(action.putGoogleCalendars.putPending, ({ putGoogleCalendars }, action) => {
      putGoogleCalendars.status = 'pending';
      putGoogleCalendars.error = null;
    });
});

import * as util from 'utils';
import * as variables from 'variables';

export const facebookBusinessApi = {
  getFacebookSuiteMe: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/facebook-suites/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  putFacebookSuiteUpdate: (data: {
    synchronizeCatalog: Boolean;
    addMessanger: Boolean;
    pixel: Boolean;
    greating: Boolean;
    greating_message: string;
  }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/facebook-suites/update`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  postFacebookSuiteDisconnect: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/facebook-suites/disconnect`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

import * as variables from 'variables';

export const cart = (
  subdomain: string,
  showMainSpinner: Function,
  hideMainSpinner: Function,
  onApproveSuccess: Function,
  setScroll: Function
) =>
  window.paypal_sdk
    .Buttons({
      style: {
        shape: 'pill',
        height: 44,
      },

      createOrder: (_data: any, _actions: any) => {
        return fetch(`https://${variables.api}/paypals/create-order/${subdomain}`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
            setScroll(true);
            return response.json();
          })
          .then((orderData) => orderData.id);
      },

      onApprove: (data: any, _actions: any) => {
        showMainSpinner();

        return fetch(
          `https://${variables.api}/paypals/capture-order/${subdomain}/${data.orderID}`,
          {
            method: 'POST',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        )
          .then(() => {
            hideMainSpinner();
            onApproveSuccess();
          })
          .catch(() => hideMainSpinner());
      },

      onCancel: function (data: any, action: any) {
        setScroll(false);
      },
    })
    .render('#paypal-button-container');

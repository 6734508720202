import { createAction } from '@reduxjs/toolkit';

import * as api from 'api';
import * as type from 'store/types';

export const getGoogleCalendarsMe = {
  get: function () {
    return (dispatch: any) => {
      dispatch(this.getPending());

      return api.googleCalendarApi
        .getGoogleCalendars()
        .then((response: any) => {
          if (response.status === 200) {
            return response.json().then((json: any) => dispatch(this.getSuccess(json)));
          } else {
            return response.json().then((json: any) => {
              throw new Error(JSON.stringify(json));
            });
          }
        })
        .catch((error: any) => {
          dispatch(this.getFailed(JSON.parse(error.message)));
          throw new Error();
        });
    };
  },

  getSuccess: createAction<any>(type.request.googleCalendar.getGoogleCalendarsSuccess),
  getFailed: createAction<any>(type.request.googleCalendar.getGoogleCalendarsFailed),
  getPending: createAction(type.request.googleCalendar.getGoogleCalendarsPending),
};

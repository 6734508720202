export const state = {
  getCalendarForItem: {
    data: (state: any) => state.request.calendar.getCalendarForItem.data,
    status: (state: any) => state.request.calendar.getCalendarForItem.status,
    error: (state: any) => state.request.calendar.getCalendarForItem.error,
  },

  getHoursForItem: {
    data: (state: any) => state.request.calendar.getHoursForItem.data,
    status: (state: any) => state.request.calendar.getHoursForItem.status,
    error: (state: any) => state.request.calendar.getHoursForItem.error,
  },
};

import * as util from 'utils';
import * as variables from 'variables';

export const addressApi = {
  getAllCountries: () => {
    return fetch(`https://${variables.api}/addresses/countries`, {
      method: 'GET',
    });
  },

  getAllAddressesMe: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/addresses/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getAddressID: (id: number) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/addresses/view/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postAddress: (data: {
    business_name: string;
    country: string;
    address_1: string;
    address_2: string;
    city: string;
    postcode: number;
  }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/addresses/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  putAddressID: (
    id: number,
    data: {
      business_name: string;
      country: string;
      address_1: string;
      address_2: string;
      city: string;
      postcode: number;
    }
  ) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/addresses/update/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteAddressID: (id: number) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/addresses/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import './style.scss';

import { ReactComponent as IconTrash } from 'images/icons/icon-trash.svg';
import { ReactComponent as IconClose } from 'images/icons/icon-close.svg';
import { ReactComponent as IconQuestion } from 'images/icons/icon-question.svg';
import { ReactComponent as IconAttention } from 'images/icons/icon-attention.svg';

import Portal from 'components/Portal';
import IconButton from 'components/common/IconButton';

interface Props {
  visible: boolean;
  typeTitle?: string;
  title: string;
  customClass: string;
  nameAddBtn?: string;
  substrateColor: string;
  onClose: () => void;
  onSave?: (() => void) | any;
  onDelete?: (() => void) | boolean;
  icon?: string;
}

const SmallPopupWrapper: React.FC<Props> = ({
  children,
  visible,
  typeTitle,
  title,
  customClass,
  nameAddBtn,
  substrateColor,
  onClose,
  onSave,
  onDelete,
  icon,
}): JSX.Element => {
  const transition = {
    transition: {
      ease: 'easeIn',
      duration: 0.3,
    },
  };

  const variantSubstrate = {
    hidden: {
      opacity: 0,
      transition,
    },
    visible: {
      opacity: 0.7,
      transition,
    },
  };

  const variantPopup = {
    hidden: {
      bottom: '-100%',
      transition,
    },
    visible: {
      bottom: 0,
      transition,
    },
  };

  useEffect(() => {
    if (visible) {
      document.body.classList.add('no-scroll');
      document.getElementsByTagName('html')[0].classList.add('no-scroll');
    } else {
      document.body.removeAttribute('class');
      document.getElementsByTagName('html')[0].removeAttribute('class');
    }
  }, [visible]);

  return (
    <AnimatePresence>
      {visible && (
        <Portal>
          <>
            <motion.div
              className={`substrate ${substrateColor}`}
              initial="hidden"
              variants={variantSubstrate}
              animate={visible ? 'visible' : 'hidden'}
              exit="hidden"
              onClick={onClose}
            ></motion.div>

            <motion.section
              className={`small-popup-wrapper ${customClass}`}
              initial="hidden"
              variants={variantPopup}
              animate={visible ? 'visible' : 'hidden'}
              exit="hidden"
            >
              <button className="indicator-btn" onClick={onClose}>
                <i className="indicator-icon"></i>
              </button>

              {typeTitle === 'v1' && (
                <h2 className="small-popup-title-v1">
                  {onDelete && (
                    <IconButton bgColor="black" onEvent={onDelete}>
                      <IconTrash className="white" />
                    </IconButton>
                  )}
                  {title}
                  <button className="add-btn" onClick={onSave}>
                    {nameAddBtn}
                  </button>
                </h2>
              )}

              {typeTitle === 'v2' && (
                <h2 className="small-popup-title-v2">
                  <IconButton bgColor="white">
                    {icon === 'question' && <IconQuestion />}
                    {icon === 'attention' && <IconAttention />}
                  </IconButton>
                  {title}
                  <IconButton className="cross" bgColor="black" onEvent={onClose}>
                    <IconClose />
                  </IconButton>
                </h2>
              )}
              {children}
            </motion.section>
          </>
        </Portal>
      )}
    </AnimatePresence>
  );
};

export default SmallPopupWrapper;

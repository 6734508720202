import * as util from 'utils';
import * as variables from 'variables';

export const googleCalendarApi = {
  getGoogleCalendars: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/google-calendars/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  putGoogleCalendars: (data: [{ id: number; active: boolean }]) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/google-calendars/update-multi`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/calendar';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.getCalendarForItem.getSuccess, ({ getCalendarForItem }, action) => {
      getCalendarForItem.status = 'success';
      getCalendarForItem.data = action.payload;
    })
    .addCase(action.getCalendarForItem.getFailed, ({ getCalendarForItem }, action) => {
      getCalendarForItem.status = 'failed';
      getCalendarForItem.error = action.payload;
    })
    .addCase(action.getCalendarForItem.getPending, ({ getCalendarForItem }) => {
      getCalendarForItem.status = 'pending';
      getCalendarForItem.error = null;
    });

  builder
    .addCase(action.getHoursForItem.getSuccess, ({ getHoursForItem }, action) => {
      getHoursForItem.status = 'success';
      getHoursForItem.data = action.payload;
    })
    .addCase(action.getHoursForItem.getFailed, ({ getHoursForItem }, action) => {
      getHoursForItem.status = 'failed';
      getHoursForItem.error = action.payload;
    })
    .addCase(action.getHoursForItem.getPending, ({ getHoursForItem }) => {
      getHoursForItem.status = 'pending';
      getHoursForItem.data = null;
      getHoursForItem.error = null;
    });
});

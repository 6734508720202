import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as reducers from 'store/reducers';
import * as actions from 'store/actions';

import AlarmPopup from 'components/popups/AlarmPopup';
import PayPopup from 'components/popups/PayPopup';
import ChatLink from 'components/common/ChatLink';

interface Props {
  children: React.ReactNode;
}

const ShowAlertPopup: React.FC<Props> = ({ children }): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [isShowPayPopup, setShowPayPopup] = useState(false);

  const dispatch: any = useDispatch();

  const plans = useSelector(reducers.request.plan.state.getAllPlansMe.data);
  const dataMe = useSelector(reducers.request.user.state.getDataMe.data);

  const getPlans = useCallback(
    () => dispatch(actions.request.plan.getAllPlansMe.get()),
    [dispatch]
  );

  useEffect(() => {
    getPlans()
      .then(() => {})
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (dataMe?.plan.days === 0) setTimeout(() => setVisible(true), 3000);
  }, [dataMe]);

  return (
    <>
      {children}

      <>
        <AlarmPopup
          visible={visible}
          title="Your trial is now over!"
          onClose={() => setVisible(false)}
        >
          <p className="alarm-message center">
            Please update your FREE plan to continue using Popstore. Tap the "Subscribe" button to
            continue.
          </p>

          <ul className="plans-list">
            {plans?.map((plan: any) => {
              if (plan.id !== dataMe?.next_plan_id) return null;

              return (
                <li
                  className={`plan-item ${plan.name.toLowerCase().split(' ').join('-')}`}
                  key={plan.id}
                >
                  <section className="plan free" onClick={() => setShowPayPopup(true)}>
                    <div className="header">
                      <h3 className="plan-name">
                        <i className="plan-icon"></i>
                        {plan.name}
                      </h3>
                      <p className="plan-price">
                        {!plan.price ? (
                          <>
                            free <span className="plan-time">trial</span>
                          </>
                        ) : (
                          <>
                            <span className="plan-currency">usd </span>
                            {plan.price}
                            <span className="plan-time">monthly</span>
                          </>
                        )}
                      </p>
                    </div>
                    <div className="footer">
                      <p className="plan-capabilities">
                        <span>
                          {dataMe.plan.transactions} | {plan.transactions} transactions
                        </span>
                      </p>
                      <p className="plan-info">
                        <button className="subscribe-btn">Subscribe</button>
                      </p>
                    </div>
                  </section>
                </li>
              );
            })}
          </ul>

          <ChatLink />
        </AlarmPopup>

        <PayPopup
          visible={isShowPayPopup}
          setShowPayPopup={setShowPayPopup}
          setShowPopup={setVisible}
          payType="plan"
        />
      </>
    </>
  );
};

export default ShowAlertPopup;

import * as utils from 'utils';
import * as variables from 'variables';

export const plan = (planID: number, onApproveSuccessPlanRequest: Function) =>
  window.paypal_sdk
    .Buttons({
      style: {
        shape: 'pill',
        height: 44,
      },

      createSubscription: (_data: any, actions: any) => {
        return fetch(`https://${variables.api}/paypals/create-subscribe/${planID}`, {
          method: 'post',
          credentials: 'same-origin',
          headers: {
            authorization: `Bearer ${utils.cookie.get('token')}`,
          },
        })
          .then((response) => response.json())
          .then((data) =>
            actions.subscription.create({
              plan_id: data.plan_id,
              custom_id: data.custom_id,
              application_context: {
                shipping_preference: 'NO_SHIPPING',
              },
            })
          );
      },

      onApprove: (data: any, _actions: any) => {
        if (data.subscriptionID) {
          onApproveSuccessPlanRequest(data.subscriptionID);
        }
        console.log('You have successfully created subscription ' + data.subscriptionID);
      },
    })
    .render('#paypal-button-container');

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request';

export const reducer = createReducer(initialState, (builder) => {
  builder
    //-----getUrlStripe
    .addCase(action.stripe.getUrlStripe.getSuccess, ({ getUrlStripe }, action) => {
      getUrlStripe.status = 'success';
      getUrlStripe.data = action.payload;
    })
    .addCase(action.stripe.getUrlStripe.getFailed, ({ getUrlStripe }, action) => {
      getUrlStripe.status = 'failed';
      getUrlStripe.error = action.payload;
    })
    .addCase(action.stripe.getUrlStripe.getPending, ({ getUrlStripe }, action) => {
      getUrlStripe.status = 'pending';
      getUrlStripe.error = null;
    });
});

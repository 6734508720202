export const state = {
  getGoogleCalendarsMe: {
    data: (state: any) => state.request.googleCalendar.getGoogleCalendarsMe.data,
    status: (state: any) => state.request.googleCalendar.getGoogleCalendarsMe.status,
    error: (state: any) => state.request.googleCalendar.getGoogleCalendarsMe.error,
  },

  putGoogleCalendars: {
    status: (state: any) => state.request.googleCalendar.putGoogleCalendars.status,
    error: (state: any) => state.request.googleCalendar.putGoogleCalendars.error,
  },
};

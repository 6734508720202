import * as util from 'utils';
import * as variables from 'variables';

export const userApi = {
  getDataMe: (param: string) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/me${param}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getDataUser: (userName: string) => {
    return fetch(`https://${variables.api}/users/user/${userName}`, {
      method: 'GET',
    });
  },

  getUserPhoneCodes: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/phone-codes`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getUserSocialGoogle: (param: any) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/social/google${param}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getUserSocialFacebook: (param: any) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/social/facebook${param}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postAvatar: (data: { image_file: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/upload-avatar`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },

  postAuthGoogle: (data: any) => {
    return fetch(`https://${variables.api}/users/social/google`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  postAuthFacebook: (data: any) => {
    return fetch(`https://${variables.api}/users/social/facebook`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  postAuthApple: () => {
    return fetch(`https://${variables.api}/users/social/apple`, {
      method: 'POST',
    });
  },

  postUserConnectFacebook: (data: { code: string; scope: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/connect/facebook`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },

  postConnectPaypal: (data: { authCode: string; sharedId: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/connect/paypal`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },

  postConnectPaypalOld: (data: { code: string; scope: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/connect/paypal`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },

  postConnectGoogleCalendar: (data: { code: string; scope: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/connect/googlecalendar`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },

  postDisconnectPaypal: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/disconnect/paypal`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postDisconnectGoogleCalendar: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/disconnect/googlecalendar`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postValidateUserName: (data: { username: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/validate-username`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },

  postCreateUserName: (data: { username: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/create-username`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },

  postCreateUser: (data: { email: string; password: string; password_repeat: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/register`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },

  postLogIn: (data: { email: string; password: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },

  postForgotPassword: (data: { email: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/forgot`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },

  postRecoverPassword: (id: number, param: string, data: { password: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/recover?${id}&${param}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },

  putDataMe: (data: {
    description: string;
    telegram: string;
    whatsapp: string;
    instagram: string;
    messenger: string;
  }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/users/update`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  },
};

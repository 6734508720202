import * as util from 'utils';
import * as variables from 'variables';

export const calendarApi = {
  getCalendarForItem: (itemID: number) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/items/calendar/${itemID}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getHoursForItem: (itemID: number, date: string) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/items/calendar-hours/${itemID}/${date}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

import * as util from 'utils';
import * as variables from 'variables';

export const itemApi = {
  getAllItemsMe: (param: string) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/items/me${param}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getAllItemsUser: (userName: string, param: string) => {
    return fetch(`https://${variables.api}/items/user/${userName}${param}`, {
      method: 'GET',
    });
  },

  getItemID: (id: number) => {
    return fetch(`https://${variables.api}/items/view/${id}`, {
      method: 'GET',
    });
  },

  postItem: (data: {}) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/items/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  postImage: (data: {}) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/items/upload-image`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  putItemID: (id: number, data: {}) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/items/update/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteItemID: (id: number) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/items/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  deleteItems: (data: object[]) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/items/delete-multi`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

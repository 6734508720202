export const stripeType = {
  getConnectStripeSuccess: 'request/getConnectStripeSuccess',
  getConnectStripeFailed: 'request/getConnectStripeFailed',
  getConnectStripePending: 'request/getConnectStripePending',

  getUrlStripeSuccess: 'request/getUrlStripeSuccess',
  getUrlStripeFailed: 'request/getUrlStripeFailed',
  getUrlStripePending: 'request/getUrlStripePending',

  postDisconnectStripeSuccess: 'request/postDisconnectStripeSuccess',
  postDisconnectStripeFailed: 'request/postDisconnectStripeFailed',
  postDisconnectStripePending: 'request/postDisconnectStripePending',

  postCreateOrderStripeSuccess: 'request/postCreateOrderStripeSuccess',
  postCreateOrderStripeFailed: 'request/postCreateOrderStripeFailed',
  postCreateOrderStripePending: 'request/postCreateOrderStripePending',

  postCreateOrderServiceStripeSuccess: 'request/postCreateOrderServiceStripeSuccess',
  postCreateOrderServiceStripeFailed: 'request/postCreateOrderServiceStripeFailed',
  postCreateOrderServiceStripePending: 'request/postCreateOrderServiceStripePending',
};

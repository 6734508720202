import * as util from 'utils';
import * as variables from 'variables';

export const stripeApi = {
  getUrlStripe: (param: string) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/stripes/link?location=${param}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postConnectStripe: (data: {}) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/stripes/connect`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  postDisconnectStripe: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/stripes/disconnect`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postCreateOrderStripe: (subdomain: string) => {
    return fetch(`https://${variables.api}/stripes/create-order/${subdomain}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  postCreateOrderServiceStripe: (subdomain: string) => {
    return fetch(`https://${variables.api}/stripes/create-service-order/${subdomain}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },
};

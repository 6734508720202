export const popupShareVariants = {
  hidden: {
    bottom: window.innerWidth > 575 ? '46.08px' : '8vw',
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
      delay: 0.1,
    },
  },
  hidden2: {
    zIndex: -1,
    transition: {
      delay: 0.4,
    },
  },

  visible: {
    zIndex: 2,
    transition: {
      duration: 0,
    },
  },
  visible2: {
    bottom: window.innerWidth > 575 ? '105.984px' : '18.400vw',
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 0.3,
      delay: 0.1,
    },
  },
};

export const shareOptionVariants = {
  hidden: {
    opacity: 0,
    zIndex: -1,
    transition: {
      duration: 0.3,
    },
  },
  hidden2: {
    zIndex: -1,
    transition: {
      delay: 0.3,
    },
  },
  visible: {
    opacity: 1,
    zIndex: 1,
    transition: {
      delay: 0.3,
    },
  },
};

export const shareButtonLeftVariants = {
  hidden: {
    borderRadius: window.innerWidth > 575 ? '19.9699px 0px 0px 19.9699px' : '3.467vw 0vw 0vw 3.467vw',
    width: window.innerWidth > 575 ? '241.906px' : '42vw',
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  },
  visible: {
    borderRadius: window.innerWidth > 575 ? '19.9699px 19.9699px 19.9699px 19.9699px' : '3.467vw 3.467vw 3.467vw 3.467vw',
    width: window.innerWidth > 575 ? '230.391px' : '40vw',
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  },
};

export const shareButtonRightVariants = {
  visible: {
    borderRadius: window.innerWidth > 575 ? '19.9699px 19.9699px 19.9699px 19.9699px' : '3.467vw 3.467vw 3.467vw 3.467vw',
    width: window.innerWidth > 575 ? '230.391px' : '40vw',
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  },

  hidden: {
    borderRadius: window.innerWidth > 575 ? '0px 19.9699px 19.9699px 0px' : '0vw 3.467vw 3.467vw 0vw',
    width: window.innerWidth > 575 ? '241.906px' : '42vw',
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  },
};

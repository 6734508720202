export const initialState: any = {
  getGoogleCalendarsMe: {
    data: null,
    status: null,
    error: null,
  },

  putGoogleCalendars: {
    status: null,
    error: null,
  },
};

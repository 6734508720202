export const initialState: any = {
  getCalendarForItem: {
    data: null,
    status: null,
    error: null,
  },

  getHoursForItem: {
    data: null,
    status: null,
    error: null,
  },
};

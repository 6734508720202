import * as type from 'store/types';
import { initialState } from './initialState';

export const cartReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ----- ADD_CHECKED_ITEMS -----

    case type.app.cartType.ADD_CHECKED_ITEMS:
      return { ...state, checkedItems: action.items };

    // ----- TOGGLE_CHECKED_ITEMS -----
    case type.app.cartType.TOGGLE_CHECKED_ITEMS: {
      let newItems: any = [];
      const { checkedItems } = state;
      const { item, quantity } = action;

      if (checkedItems.some((currentItem: any) => currentItem.id === item.id)) {
        newItems = checkedItems.filter((currentItem: any) => currentItem.id !== item.id);
      } else {
        newItems = [
          ...checkedItems,
          {
            id: item.id,
            quantity: quantity,
            price: item.price,
            type_id: item.type_id,
            delivery_options_id: item.delivery_options.map((item: any) => item.id),
          },
        ];
      }

      return { ...state, checkedItems: newItems };
    }

    // ----- CHANGE_QUANTITY_IN_CHECKED_ITEMS -----
    case type.app.cartType.CHANGE_QUANTITY_IN_CHECKED_ITEMS: {
      let newItems: any = [];
      const { checkedItems } = state;
      const { item, count } = action;

      newItems = checkedItems.map((currentItem: any) => {
        if (currentItem.id !== item.id) return currentItem;

        return { ...currentItem, quantity: count };
      });

      return { ...state, checkedItems: newItems };
    }

    default:
      return state;
  }
};

import * as variables from 'variables';

export const cartApi = {
  getAllItemsForCart: (userName: string) => {
    return fetch(`https://${variables.api}/carts/get-items/${userName}`, {
      method: 'GET',
      credentials: 'include',
    });
  },

  getAllItemsSuccessfulForCart: (userName: string) => {
    return fetch(`https://${variables.api}/carts/success/${userName}`, {
      method: 'GET',
      credentials: 'include',
    });
  },

  addProductToCart: (userName: string, data: { item_id: number; quantity: number }) => {
    return fetch(`https://${variables.api}/carts/add/${userName}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  addServiceToCart: (userName: string, data: any) => {
    return fetch(`https://${variables.api}/carts/add-service/${userName}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  addProductsToCart: (userName: string, data: { item_id: number; quantity: number }[]) => {
    return fetch(`https://${variables.api}/carts/add-multi/${userName}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  changeQuantityForProductInCart: (
    userName: string,
    data: { item_id: number; quantity: number }
  ) => {
    return fetch(`https://${variables.api}/carts/set-quantity/${userName}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  changeQuantityForProductsInCart: (
    userName: string,
    data: { item_id: number; quantity: number }[]
  ) => {
    return fetch(`https://${variables.api}/carts/set-quantity-multi/${userName}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  changeDeliveryForProductsInCart: (
    userName: string,
    data: { item_id: number; delivery_option_id: number }[]
  ) => {
    return fetch(`https://${variables.api}/carts/set-delivery-multi/${userName}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  changeAddressForProductsInCart: (
    userName: string,
    data: { item_id: number; address_id: number }[]
  ) => {
    return fetch(`https://${variables.api}/carts/set-address-multi/${userName}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteItemFromCart: (userName: string, data: { item_id: number }) => {
    return fetch(`https://${variables.api}/carts/delete/${userName}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

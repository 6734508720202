import * as util from 'utils';
import * as variables from 'variables';

export const customPaymentApi = {
  postConnectCustomPayment: (data: {}) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/custom-payments/connect`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  postDisconnectCustomPayment: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}/custom-payments/disconnect`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postCreateOrderCustomPayment: (data: {}, subdomain: string) => {
    return fetch(`https://${variables.api}/custom-payments/create-order/${subdomain}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  postCreateOrderServiceCustomPayment: (data: {}, subdomain: string) => {
    return fetch(`https://${variables.api}/custom-payments/create-service-order/${subdomain}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};
